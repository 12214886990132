exports.components = {
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-branded-contentful-featured-work-slug-tsx": () => import("./../../../src/pages/branded/{contentfulFeaturedWork.slug}.tsx" /* webpackChunkName: "component---src-pages-branded-contentful-featured-work-slug-tsx" */),
  "component---src-pages-branded-index-tsx": () => import("./../../../src/pages/branded/index.tsx" /* webpackChunkName: "component---src-pages-branded-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-directors-index-tsx": () => import("./../../../src/pages/directors/index.tsx" /* webpackChunkName: "component---src-pages-directors-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-contentful-news-list-slug-tsx": () => import("./../../../src/pages/news/{contentfulNewsList.slug}.tsx" /* webpackChunkName: "component---src-pages-news-contentful-news-list-slug-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-original-development-contentful-featured-work-slug-tsx": () => import("./../../../src/pages/original-development/{contentfulFeaturedWork.slug}.tsx" /* webpackChunkName: "component---src-pages-original-development-contentful-featured-work-slug-tsx" */),
  "component---src-pages-original-development-index-tsx": () => import("./../../../src/pages/original-development/index.tsx" /* webpackChunkName: "component---src-pages-original-development-index-tsx" */)
}

