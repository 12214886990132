import React from 'react';
import './src/shared/styles/global.css';
// import {Loader} from './src/shared/ui/Loader';

export const wrapRootElement = ({ element }) => {
    // Wrap your application root element with the loader
    return (
      <>
        {/* <Loader /> */}
        {element}
      </>
    );
  };
  
export const shouldUpdateScroll   = () => {
  window.scrollTo(0, 0);
  return false;
}

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) { window.scrollTo(0, 0)}
}  

export const onInitialClientRender = () => {
  window.history.scrollRestoration = 'manual';
  // document.documentElement.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`
  // document.documentElement.style.overflowY = 'hidden'
};